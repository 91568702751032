// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Signup-module__signUpDropdownButton___1R-UY{font-family:Avenir,\"Lato\",sans-serif;font-style:normal;font-weight:800;font-size:14px;line-height:19px;color:#ffffff !important;background:linear-gradient(104.04deg, #617aff 0%, #798bff 52.08%, #8c95fe 100%);padding:10px 27px;border-radius:16px;margin-left:15px;width:100px}.Signup-module__signUpDropdownButton___1R-UY:after{display:none}.Signup-module__signUpDropdownButton___1R-UY:focus{background:none !important}@media only screen and (min-device-width: 320px) and (max-device-width: 1199px){.Signup-module__signupDropdown___3hRA5{position:absolute;top:15px;right:15px}}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241213122051/app/javascript/components/layout/Navbar/Signup/styles/Signup.module.scss"],"names":[],"mappings":"AAEA,6CACI,oCAAqC,CACrC,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,wBAAyB,CACzB,+EAKC,CACD,iBAAkB,CAClB,kBAAmB,CACnB,gBAAiB,CACjB,WAAY,CAhBhB,mDAmBQ,YAAa,CAnBrB,mDAsBQ,0BAA2B,CAC9B,gFAGD,uCACI,iBAAkB,CAClB,QAAS,CACT,UAAW,CACd","file":"Signup.module.scss","sourcesContent":["@import '../../../../../styles/var.scss';\n\n.signUpDropdownButton {\n    font-family: Avenir,\"Lato\",sans-serif;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 14px;\n    line-height: 19px;\n    color: #ffffff !important;\n    background: linear-gradient(\n        104.04deg,\n        #617aff 0%,\n        #798bff 52.08%,\n        #8c95fe 100%\n    );\n    padding: 10px 27px;\n    border-radius: 16px;\n    margin-left: 15px;\n    width: 100px;\n\n    &:after {\n        display: none;\n    }\n    &:focus {\n        background: none !important;\n    }\n}\n@media only screen and (min-device-width : 320px) and (max-device-width : 1199px){\n    .signupDropdown{\n        position: absolute;\n        top: 15px;\n        right: 15px;\n    }\n}"]}]);
// Exports
exports.locals = {
	"signUpDropdownButton": "Signup-module__signUpDropdownButton___1R-UY",
	"signupDropdown": "Signup-module__signupDropdown___3hRA5"
};
module.exports = exports;
