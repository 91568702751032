import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'
import feather from 'feather-icons'
import Util from '../../../utils/util'

const NavbarRecruiterLogin = ({
    login
}) => {
   
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040, borderBottom: '6px solid #005999' }}>
                <Navbar.Brand href="/communities">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>Cardinal Groups</span>{' '}
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className='mr-3'>
                        <Nav.Link  href="/welcome/investors">Investors</Nav.Link>
                        <Nav.Link href="https://cardinaltalent.ai/">Employers</Nav.Link>
                        <Nav.Link href="/talent_home">Talent</Nav.Link>
                        <Nav.Link  href="/welcome/recruiter">Recruiters</Nav.Link>
                        <Nav.Link  className='activeLinkColorTop' style={{color: '#000'}}>Communities</Nav.Link>
                    </Nav>
                    <Nav className='mr-3'>
                        <Nav.Link  href="/signup/talent" style={{color: '#005999'}}>Sign up</Nav.Link><span className='mt-2'> /</span>
                        <Nav.Link  href="/users/sign_in" style={{color: '#005999'}}> Login in</Nav.Link>
                       
                    </Nav>
                    
                </Navbar.Collapse>
                   
            </Navbar>
        </>
    )
}

export default NavbarRecruiterLogin
