import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import feather from 'feather-icons'
import styles from './styles/index.module.scss'
import SubscriptionIcon from '../../../../assets/images/icons/subscription.png'

const FinishSubscriptionPage = ({currentUser,allow_deduct}) => {
  console.log('allow_deduct')
  console.log(allow_deduct)
  return (
    <div className={styles.errorPage}>
      <section className={styles.getStartedSection}>
        <Row className={styles.newRow}>
          <Col md="12" lg="12" className={styles.getStartedColumn}>
            { allow_deduct ? 
            <div>
              <Image src={SubscriptionIcon}></Image>
              <p className={styles.subtitle}>
                Your Organization have reached monthly limit. 
              </p>
              <p className={styles.text}>
                Please contact your organization admin.
              </p>
            </div> :
            <div>
              <Image src={SubscriptionIcon}></Image>
              <p className={styles.subtitle}>
                You have reached your free monthly limit.
              </p>
              <Button
                className={styles.getStartedButton}
                href="/subscription/order"
              >
                View Subscription Plan
              </Button>
              <p className={styles.text}>
                Please email <a href="mailto:sales@cardinaltalent.ai"> sales@cardinaltalent.ai </a> if you'd like a premium account.
              </p>
            </div>}
            <p className={styles.text}>
              You can also earn credits by inviting people to join your network, by <a href="/communities/new">creating a group</a>, or by creating an <a href="/ai_interviews/create">AI Interview</a>.
            </p>
            <p className={styles.text}>
              For each person who joins as a result of your invitation you earn 1 database view credit.
            </p>
          </Col>
        </Row>
      </section>
    </div>
  )
}
export default FinishSubscriptionPage
