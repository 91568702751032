import React, { useState } from 'react';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

const PhoneCallPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [questions, setQuestions] = useState(['']);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const handleAddQuestion = () => {
      setQuestions([...questions, '']);
    };
  
    const handleQuestionChange = (index, value) => {
      const updatedQuestions = [...questions];
      updatedQuestions[index] = value;
      setQuestions(updatedQuestions);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post('/screening_calls/start_call', {
          phone_number: phoneNumber,
          questions,
        });
        setMessage(response.data.message);
      } catch (error) {
        setMessage(error.response?.data?.error || 'Error initiating the call');
      }
    };

    // const handleSubmit = async () => {
    //     // Add your form submission logic 
    //     e.preventDefault();
    //     const payload = new FormData()
    //     const url = 'screening_calls/start_call'
    //     payload.append('phone_number', phoneNumber)
    //     payload.append('questions',  questions)
    //     setLoading(true)
    //     await makeRequest(url, 'post', payload, {
    //         contentType: 'application/json',
    //         loadingMessage: 'Submitting...',
    //         onSuccess: (res) => {
    //             console.log(res)
    //             setLoading(false)   
    //         },
    //     })
    // };
  
    return (
        <div className="container mt-5">
          <h2 className="mb-4 text-center">Start Screening Call</h2>
          {message && (
            <div className={`alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`} role="alert">
              {message}
            </div>
          )}
          <form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm bg-light">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                id="phoneNumber"
                className="form-control"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Questions</label>
              {questions.map((question, index) => (
                <div key={index} className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={question}
                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                  />
                  {questions.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        const updatedQuestions = questions.filter((_, i) => i !== index);
                        setQuestions(updatedQuestions);
                      }}
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button type="button" className="btn btn-primary" onClick={handleAddQuestion}>
                Add Question
              </button>
            </div>
            <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-success">
                    {loading ? (
                        <Spinner
                            animation="border"
                            variant="light"
                        />
                    ) : (
                        'Start Call'
                    )}
                </button>
            </div>
          </form>
        </div>
      );
    };
  
  export default PhoneCallPage;
