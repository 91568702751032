import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import ProfileAvatar from '../../common/ProfileAvatar/ProfileAvatar'
import RecruiterOrganization from '../../pages/RecruiterOrganization/RecruiterOrganization'
import styles from './styles/Navbar.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Signup from './Signup/Signup'
import { getOrganizationFavorite } from '../../api/recruiter.api'
import feather from 'feather-icons'

const NavBar = ({
    isAdmin,
    isAgency,
    isAgencyAdmin,
    isSales,
    canPeopleSearch,
    canPeopleIntake,
    canJobView,
    userId,
    isTalent,
    isEmployer,
    isRecruiter,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    url,
    isTeamOwner,
    isFinancer,
    base_url,
    isTextMessenger,
    host_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [recruiterOrganizations, setRecruiterOrganizations] = useState([])
    const [recruiterOrganizationFavs, setRecruiterOrganizationFavs] = useState([])
    const [teamOwner, setTeamOwner] = useState([])
    const userBelongsToCT = organization?.name === window.ch_const.ct_org_name
    const userFromAgency = (organization?.agency_id != 0) ? true : false
    const has_commission_recruiter = (currentUser?.is_commission_recruiter == 'yes') ? true : false
    const subOrgBelongsToCT =
        memberOrganization?.name === window.ch_const.ct_org_name
    const fetchOrganization = async () => {
        try {
            const response = await getOrganizationFavorite();
            setRecruiterOrganizations(response);    
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isRecruiter) {
            fetchOrganization();
        }
    }, []);


    const handleChangeOrganization = async (organization_id) => {
        const url = '/change_recruiter_organization'

        const payload = {
            organization_id: organization_id,
            user_id: userId,
        }

        const response = await makeRequest(url, 'put', payload, {
            contentType: 'application/json',
            loadingMessage: 'changing organization...',
            createResponseMessage: (response) => {
                return response.message
            },
            onSuccess: () => {
                setTimeout(() => {
                    window.location.href = '/jobs'
                }, 1200)
            },
        })
        // window.location.reload(true)
    }

    
    const guestLinks = (
        <>
            <Nav.Link
                href="/job_search"
                className={`${styles.navbarLink} ${currentPathname === '/job_search'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Find Work
            </Nav.Link>
            <Nav.Link
                href="/welcome/employer"
                className={`${styles.navbarLink} ${currentPathname === '/welcome/employer'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                For Employers
            </Nav.Link>
            <Nav.Link
                href="/welcome/recruiter"
                className={`${styles.navbarLink} ${currentPathname === '/welcome/recruiter'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                For Recruiters
            </Nav.Link>

            <Nav.Link
                href="/welcome/refer_for_rewards"
                className={`${styles.navbarLink} ${currentPathname === '/welcome/refer_for_rewards'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Refer for Rewards
            </Nav.Link>

            {/* <Nav.Link
                href="/welcome/on_demand_recruiter"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/on_demand_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                On-Demand Recruiter
            </Nav.Link> */}
        </>
    )
    const authLinks = (
        <>
            {isAdmin && (
                <Nav.Link
                    data-test="admin-link"
                    href="/admin"
                    className={`${styles.navbarLink} ${currentPathname === '/admin'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Admin
                </Nav.Link>
            )}

            {(isEmployer || isRecruiter ) && (
                <Nav.Link
                    href="/dashboard/"
                    className={`${styles.navbarLink} ${currentPathname === '/dashboard/' ||
                            currentPathname === '/dashboard'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Dashboard
                </Nav.Link>
            )}

            {(isEmployer  || isRecruiter || isAdmin) && (
                <Nav.Link
                    href="/jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/jobs' ||
                            currentPathname === '/jobs/new'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    My Jobs
                </Nav.Link>
            )}

            {(isEmployer || isRecruiter || isAdmin) && canPeopleSearch && !has_commission_recruiter && (
                <Nav.Link
                    href="/people_searches/new"
                    className={`${styles.navbarLink} ${currentPathname === '/people_searches/new'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Candidate Search
                </Nav.Link>
            )}
            {/* {(isEmployer) && canPeopleSearch && (
                <Nav.Link
                    href="/free_candidate_search/"
                    className={`${styles.navbarLink} ${currentPathname === '/free_candidate_search/'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Free Candidate Search
                </Nav.Link>
            )} */}
              {(isEmployer) && canPeopleSearch && (
                <Nav.Link
                    href="/people_searches/new/?page=free"
                    className={`${styles.navbarLink} ${pageName === 'free'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Free Candidate Search
                </Nav.Link>
            )}
            {isAdmin && canPeopleIntake && (
                <Nav.Link
                    href="/candidates/new"
                    className={`${styles.navbarLink} ${currentPathname === '/candidates/new'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Intake candidates
                </Nav.Link>
            )}

            {isAdmin && canJobView && (
                <Nav.Link
                    href="/jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/jobs'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Jobs
                </Nav.Link>
            )}
            {/* {(isAdmin || isEmployer || isRecruiter) && (
                <Nav.Link
                    href="/scheduled_interviews"
                    className={`${styles.navbarLink} ${currentPathname === '/scheduled_interviews'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Interviews
                </Nav.Link>
            )} */}
             {(isEmployer || isRecruiter) && (
                <Nav.Link
                    href="/interview_scheduled"
                    className={`${styles.navbarLink} ${currentPathname === '/interview_scheduled'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Interviews Scheduled
                </Nav.Link>
            )}

            {isRecruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                <Nav.Link
                    href="/job/cardinal_jobs"
                    className={`${styles.navbarLink} ${currentPathname === '/job/cardinal_jobs'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Cardinal Jobs
                </Nav.Link>
            )}

            {isRecruiter && !has_commission_recruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                <Nav.Link
                    href={`/users/${currentUser.id}/timesheet`}
                    className={`${styles.navbarLink} ${currentPathname === `/users/${currentUser.id}/timesheet`
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Time Entry
                </Nav.Link>
            )}

            {(isTalent || isAdmin) && (
                <Nav.Link
                    href="/talent_home"
                    className={`${styles.navbarLink} ${currentPathname === '/talent_home'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    My Matches
                </Nav.Link>
            )}

            {(isTalent || isAdmin) && (
                <Nav.Link
                    href="/job_search"
                    className={`${styles.navbarLink} ${currentPathname === '/job_search'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Find Work
                </Nav.Link>
            )}

            {(isTalent || isAdmin) && (
                <Nav.Link
                    href="/submissions"
                    className={`${styles.navbarLink} ${currentPathname === '/submissions'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    My Applications
                </Nav.Link>
            )}
            {/* {(isTalent) && (
                <Nav.Link
             href="/request_interviews"
             className={`${styles.navbarLink} ${currentPathname === '/request_interviews'
                     ? styles.activeLinkColor
                     : ''
                 }`}
                >
             Requests
                 </Nav.Link>
            )} */}
            {(isTalent) && (
                <Nav.Link
             href="/search_employer"
             className={`${styles.navbarLink} ${currentPathname === '/search_employer'
                     ? styles.activeLinkColor
                     : ''
                 }`}
                >
             Search Decision Makers
                 </Nav.Link>
            )}
              {(isTalent) && (
                <Nav.Link
             href="/candidate_interview_scheduled"
             className={`${styles.navbarLink} ${currentPathname === '/candidate_interview_scheduled'
                     ? styles.activeLinkColor
                     : ''
                 }`}
                >
             Scheduled Interviews
                 </Nav.Link>
            )}

            {(isEmployer || isAdmin) && (
                <>
                    <Nav.Link
                        href="/organizations"
                        className={`${styles.navbarLink} ${currentPathname === '/organizations'
                                ? styles.activeLinkColor
                                : ''
                            }`}
                    >
                        Organization
                    </Nav.Link>
                </>
            )}

            {/* {(isTalent || isAdmin) && (
                <Nav.Link
                    href="/my_connections"
                    className={`${styles.navbarLink} ${currentPathname === '/my_connections'
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    My Connections
                </Nav.Link>
            )} */}
            {(isAdmin || isEmployer || isRecruiter)  && (
                <li>
                    <Nav.Link
                        className={`${styles.navbarLink} ${ currentPathname.includes('analytic') || currentPathname == '/auto_candidate_submission'
                                ? styles.activeLinkColor
                                : ''
                            } p-2`}
                    >
                        Analytics <i data-feather="chevron-down" style={{
                            width: '1.2rem',
                            height: '1.2rem',
                        }}></i>
                    </Nav.Link>
                    <ul>
                        {/* <li>
                            <Nav.Link
                                href="/analytic/job_recruiters_analytic"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/job_recruiters_analytic'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Jobs
                            </Nav.Link>
                        </li> */}
                        <li>
                            <Nav.Link
                                href="/analytic/recruiter_jobs_analytics"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/recruiter_jobs_analytics'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Jobs
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/analytic/submitted_candidates_analytic"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/submitted_candidates_analytic'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Submitted Candidate
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/analytic/incoming_mail_dashboard"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/incoming_mail_dashboard'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Incoming Mail Dashboard
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/analytic/automation_mail_dashboard"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/automation_mail_dashboard'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Automation Email Dashboard
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/auto_candidate_submission"
                                className={`${styles.navbarLink} ${currentPathname === '/auto_candidate_submission'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Automation Text Dashboard
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/analytic/automation_analytic"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/automation_analytic'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Automation Analytics
                            </Nav.Link>
                        </li>
                      {currentUser?.agency_id === 0 && !has_commission_recruiter && <li>
                            <Nav.Link
                                href="/analytic/organization_onboard"
                                className={`${styles.navbarLink} ${currentPathname === '/analytic/organization_onboard'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Client Onboarding
                            </Nav.Link>
                        </li> }
                        {(isRecruiter) && (userBelongsToCT || subOrgBelongsToCT) && !has_commission_recruiter &&  (
                            <li>
                                <Nav.Link
                                    href="/analytic/cross_submissions_analytic"
                                    className={`${styles.navbarLink} ${currentPathname === '/analytic/cross_submissions_analytic'
                                            ? styles.activeLinkColor
                                            : ''
                                        }`}
                                >
                                    Cross Submissions
                                </Nav.Link>
                            </li>
                        )}
                        {(isRecruiter) && (isTeamOwner) && !has_commission_recruiter && (
                            <>
                                <li>
                                    <Nav.Link
                                        href="/analytic/recruiter_teams_analytic"
                                        className={`${styles.navbarLink} ${currentPathname === '/analytic/recruiter_teams_analytic'
                                                ? styles.activeLinkColor
                                                : ''
                                            }`}
                                    >
                                        Team
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link
                                        href="/analytic/recruiters_assignments_analytic"
                                        className={`${styles.navbarLink} ${currentPathname === '/analytic/recruiters_assignments_analytic'
                                                ? styles.activeLinkColor
                                                : ''
                                            }`}
                                    >
                                        Recruiters Assignments
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link
                                        href="/analytic/in_mails"
                                        className={`${styles.navbarLink} ${currentPathname === '/analytic/in_mails'
                                                ? styles.activeLinkColor
                                                : ''
                                            }`}
                                    >
                                        InMails
                                    </Nav.Link>
                                </li>
                            </>
                        )}
                    </ul>
                </li>
            )}
            {((isRecruiter || isEmployer) && userFromAgency == false) && !has_commission_recruiter && (
                <li>
                    <Nav.Link
                        className={`${styles.navbarLink} ${(currentPathname == '/in_mails' || currentPathname == '/job_leads' || currentPathname.includes('job_leads') == true)
                                ? styles.activeLinkColor
                                : ''
                            } p-2`}
                    >
                        Tasks <i data-feather="chevron-down" style={{
                            width: '1.2rem',
                            height: '1.2rem',
                        }}></i>
                    </Nav.Link>
                    <ul>
                        {isRecruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                            <li>
                                <Nav.Link
                                    href="/in_mails"
                                    className={`${styles.navbarLink} ${currentPathname === '/in_mails'
                                            ? styles.activeLinkColor
                                            : ''
                                        }`}
                                >
                                    InMails
                                </Nav.Link>
                            </li>
                        )}
                        {/* <li>
                            <Nav.Link
                                href="/auto_candidate_submission"
                                className={`${styles.navbarLink} ${(currentPathname.includes('auto_candidate_submission') == true) || currentPathname === '/auto_candidate_submission'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                SMS Screening Candidate
                            </Nav.Link>
                        </li> */}
                        <li>
                            <Nav.Link
                                href="/job_leads"
                                className={`${styles.navbarLink} ${(currentPathname.includes('job_leads') == true) || currentPathname === '/job_leads'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Review Leads
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/job_process_leads"
                                className={`${styles.navbarLink} ${(currentPathname.includes('job_process_leads') == true) || currentPathname === '/job_process_leads'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Process Leads
                            </Nav.Link>
                        </li>
                        {currentUser.designation == "marketer" &&
                            <li>
                                <Nav.Link
                                    href="/email_datas"
                                    className={`${styles.navbarLink} ${(currentPathname.includes('email_datas') == true) || currentPathname === '/email_datas'
                                            ? styles.activeLinkColor
                                            : ''
                                        }`}
                                >
                                    Email Data
                                </Nav.Link>
                            </li>
                        }
                    </ul>
                </li>
            )}
            {(isRecruiter || isEmployer) && (userBelongsToCT || subOrgBelongsToCT) && !has_commission_recruiter && (
                <li>
                    <Nav.Link
                        className={`${styles.navbarLink} ${currentPathname == "/sourcing_schedules"
                                ? styles.activeLinkColor
                                : ''
                            } p-2`}
                    >
                        Sourcing <i data-feather="chevron-down" style={{
                            width: '1.2rem',
                            height: '1.2rem',
                        }}></i>
                    </Nav.Link>
                    <ul>
                        <li>
                            <Nav.Link
                                href="/sourcing_schedules"
                                className={`${styles.navbarLink} ${currentPathname === '/sourcing_schedules'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Sourcing Schedules
                            </Nav.Link>
                        </li>
                    </ul>
                </li>
            )}
            {isRecruiter && (userBelongsToCT || subOrgBelongsToCT) && (
                <Nav.Link
                    href={`/weekly_referral`}
                    className={`${styles.navbarLink} ${currentPathname.includes('/weekly_referral')
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Referral
                </Nav.Link>
            )}
            {/* { (currentUser?.account_email_verified == true) &&
                <Nav.Link
                    href={`/bulk_upload`}
                    className={`${styles.navbarLink} ${currentPathname.includes('/bulk_upload')
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Bulk Upload
               </Nav.Link>
            }
            { (currentUser?.account_email_verified == true) &&
                <Nav.Link
                    href={`/campaigns_list`}
                    className={`${styles.navbarLink} ${currentPathname.includes('/campaigns_list')
                            ? styles.activeLinkColor
                            : ''
                        }`}
                >
                    Campaigns
               </Nav.Link>
            } */}
            {/* {( isAdmin ) && (
                <Dropdown drop="down">
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        className={styles.navbarUploadCandidatesButton}
                    >
                        &#43; Upload Candidates
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        align="right"
                        className={styles.navbarUploadCandidatesButtonMenu}
                    >
                        <Dropdown.Item href="/single_candidate_upload">
                            Add single candidate
                        </Dropdown.Item>
                        <Dropdown.Item href="/bulk_candidate_upload">
                            Bulk upload
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )} */}
        </>
    )
    const agencyAdmin = (
        <>
            <Nav.Link
                href="/agencies/admin/dashboard"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/admin/dashboard'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            <Nav.Link
                href="/agencies/admin/agencies"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/admin/agencies'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Agency Management
            </Nav.Link>
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${
                        currentPathname.includes('/agencies/admin/reports/organizations') || currentPathname === '/agencies/admin/reports/organizations' || currentPathname === '/agencies/admin/reports/jobs' || currentPathname.includes('/agencies/admin/reports/jobs') || currentPathname === '/agencies/admin/reports/recruiters' || currentPathname.includes('/agencies/admin/reports/recruiters') || currentPathname === '/agencies/admin/reports/candidates' || currentPathname.includes('/agencies/admin/reports/candidates')
                            ? styles.activeLinkColor
                            : ''
                    } p-2`}
                >
                    Reports <i data-feather="chevron-down" style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/agencies/admin/reports/organizations"
                            className={`${styles.navbarLink} ${
                                currentPathname.includes('/agencies/admin/reports/organizations') || currentPathname === '/agencies/admin/reports/organizations'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Organizations
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/admin/reports/jobs"
                            className={`${styles.navbarLink} ${
                                currentPathname.includes('/agencies/admin/reports/jobs') || currentPathname === '/agencies/admin/reports/jobs'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Jobs
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/admin/reports/recruiters"
                            className={`${styles.navbarLink} ${
                                currentPathname.includes('/agencies/admin/reports/recruiters') || currentPathname === '/agencies/admin/reports/recruiters'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Recruiters
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/admin/reports/candidates"
                            className={`${styles.navbarLink} ${
                                currentPathname.includes('/agencies/admin/reports/candidates') || currentPathname === '/agencies/admin/reports/candidates'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Candidates
                        </Nav.Link>
                    </li>
                </ul>
            </li>
        </>
    )
    const agency = (
        <>
            <Nav.Link
                href="/agencies/dashboard"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/dashboard'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            <Nav.Link
                href="/agencies/recruiters"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/recruiters'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Recruiters
            </Nav.Link>

            {/* <Nav.Link
                href="/agencies/employes"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/employes'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Employees
            </Nav.Link> */}
            <Nav.Link
                href="/agencies/organizations"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/organizations'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Organizations
            </Nav.Link>
            <Nav.Link
                href="/agencies/jobs"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/jobs'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Jobs
            </Nav.Link>

            <Nav.Link
                href="/agencies/teams"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/teams'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Teams
            </Nav.Link>

            <Nav.Link
                href="/agencies/candidates/candidate_management"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/candidates/candidate_management'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Candidate
            </Nav.Link>
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${
                        currentPathname.includes('analytic')
                            ? styles.activeLinkColor
                            : ''
                    } p-2`}
                >
                    Analytics <i data-feather="chevron-down" style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/agencies/analytic/job"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/agencies/analytic/job'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Jobs
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/analytic/organization"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/agencies/analytic/organization'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Organizations
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/analytic/recruiter"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/agencies/analytic/recruiter'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Recruiters
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/agencies/analytic/team"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/agencies/analytic/team'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Teams
                        </Nav.Link>
                    </li>
                </ul>
            </li>
        </>
    )
    const adminLinks = (
        <>
            <Nav.Link
                href="/admin/dashboard"
                className={`${styles.navbarLink} ${currentPathname === '/admin/dashboard'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Home
            </Nav.Link>
            <Nav.Link
                href="/admin/users"
                className={`${styles.navbarLink} ${currentPathname === '/admin/users'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Users
            </Nav.Link>
             <li>
                 <Nav.Link
                    className={`${styles.navbarLink} ${currentPathname === '/admin/recruiter_management' || currentPathname === '/admin/recruiter_sourcer_meeting'
                            ? styles.activeLinkColor
                            : ''
                        } p-2`}
                >
                    Recruiter <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
            
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/recruiter_management"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/recruiter_management'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Recruiter List
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/commission_recruiter_management"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/commission_recruiter_management'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            100% Commission Recruiters 
                        </Nav.Link>
                    </li>
                    <li>
                       <Nav.Link
                            href="/admin/recruiter_sourcer_meeting"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/recruiter_sourcer_meeting'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Sourcer Meeting
                        </Nav.Link>
                    </li>
                </ul>
            </li>
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${currentPathname.includes('candidate')
                            ? styles.activeLinkColor
                            : ''
                        } p-2`}
                >
                    Candidate <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/candidate_management"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/candidate_management'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Candidate List
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/submitted_candidates"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/submitted_candidates'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Submitted Candidates
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/candidate_view_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/candidate_view_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Candidate View Analytics
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/upload_candidate_leads"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/upload_candidate_leads'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Candidate Campaign Leads
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/candidate_text_campaign"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/candidate_text_campaign'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Text Message Campaigns
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/auto_candidate_submission"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/auto_candidate_submission'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Auto Candidate Submission
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/auto_candidate_scr_submission"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/auto_candidate_scr_submission'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            SMS Screening Candidates
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/auto_candidate_report"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/auto_candidate_report'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Candidate Status for Fee Agreement
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/chat_faqs"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/chat_faqs'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Chat FAQs
                        </Nav.Link>
                    </li>
                </ul>
            </li>
            
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${currentPathname.includes('/admin/organizations/') || currentPathname === '/admin/organizations' || currentPathname === '/admin/in_mails_assignments' || currentPathname.includes('/admin/in_mails_assignments')
                            ? styles.activeLinkColor
                            : ''
                        } p-2`}
                >
                    Organizations <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/organizations"
                            className={`${styles.navbarLink} ${currentPathname.includes('/admin/organizations/') || currentPathname === '/admin/organizations'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Organizations List
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/in_mails_assignments"
                            className={`${styles.navbarLink} ${currentPathname.includes('/admin/in_mails_assignments') || currentPathname === '/admin/in_mails_assignments'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            InMails Assignments
                        </Nav.Link>
                    </li>
                </ul>
            </li>

           
         
            <Nav.Link
                href="/admin/jobs"
                className={`${styles.navbarLink} ${currentPathname === '/admin/jobs'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Jobs
            </Nav.Link>
            <Nav.Link
                href="/admin/decision_makers"
                className={`${styles.navbarLink} ${currentPathname === '/admin/decision_makers'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Decision Makers
            </Nav.Link>
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${currentPathname.includes('analytic') && !currentPathname.includes('candidate_view_analytic') && !currentPathname.includes('mixmax_job_analytic')
                            ? styles.activeLinkColor
                            : ''
                        } p-2`}
                >
                    Analytics <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/jobs_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/jobs_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Jobs
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/organizations_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/organizations_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Organizations
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/recruiters_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/recruiters_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Recruiters
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/recruiters_assignments_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/recruiters_assignments_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Recruiters Assignments
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/team_leads_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/team_leads_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Team
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/in_mails"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/in_mails'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            InMails
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/email_datas"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/email_datas'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Campaign Manager
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/campaign_manager_lead"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/campaign_manager_lead'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Campaign Manager Lead
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/cross_submission_recruiters_analytic"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/cross_submission_recruiters_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Cross Submissions
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/lead_sourcer"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/lead_sourcer'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Sourcer Analytics
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/hire_analytics"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/hire_analytics'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Hired Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/okr_analytic"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/okr_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            OKR
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/submission_report"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/submission_report'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Candidate Submission Report
                        </Nav.Link>
                    </li>
                </ul>
            </li>
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${(currentPathname === '/admin/candidate_lead_report' || currentPathname === '/admin/job_lead_report')
                            ? styles.activeLinkColor
                            : ''
                        } p-2`}
                >
                    Reports <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/candidate_lead_report"
                            className={`${styles.navbarLink} ${
                                (currentPathname === '/admin/candidate_lead_report')
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Candidate Lead Report
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/job_lead_report"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/job_lead_report'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Candidate Lead Report(Job)
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/organization_onboard"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/organization_onboard'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Client Onboarding
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/client_dashboard/hourly"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/client_dashboard/hourly'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Hourly Organization Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/client_dashboard/contingency"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/client_dashboard/contingency'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Contingency Organization Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/recommended_candidate"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/recommended_candidate'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Recommended Candidate
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/red_clients_analytics"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/red_clients_analytics'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Red Client
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/low_inmail_log"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/low_inmail_log'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Low InMails Send Log
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/full_cycle_outreach"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/full_cycle_outreach'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Full Cycle Outreach
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/sourcer_meeting_analytic"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/sourcer_meeting_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                            Sourcer Meeting
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/ctrs_reports"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/ctrs_reports'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          CTRS Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/mid_week_metrics"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/mid_week_metrics'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          Mid Week Metrics Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/recruiter_submission_dashboard"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/recruiter_submission_dashboard'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          AM Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/automation_email_dashboard"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/automation_email_dashboard'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          Automation Email Dashboard
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/automation_email_forward"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/automation_email_forward'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          Automation Email Forward
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/automation_analytic"
                            className={`${styles.navbarLink} ${
                                currentPathname === '/admin/automation_analytic'
                                    ? styles.activeLinkColor
                                    : ''
                            }`}
                        >
                          Automation Analytics
                        </Nav.Link>
                    </li>
                </ul>
            </li>
            
            {/* <Nav.Link
                href="/admin/timesheet"
                className={`${styles.navbarLink} ${
                    currentPathname === '/admin/timesheet'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Entry Report
            </Nav.Link> */}
            <li>
                <Nav.Link
                    className={`${styles.navbarLink} ${currentPathname == "/admin/sourcing_schedules" || currentPathname == "/admin/lead_status" || currentPathname == "/admin/sales_leads"
                            ? styles.activeLinkColor 
                            : ''
                        } p-2`}
                >
                    Sourcing <i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
                </Nav.Link>
                <ul>
                    <li>
                        <Nav.Link
                            href="/admin/sourcing_schedules"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/sourcing_schedules'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Sourcing Schedules
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/lead_status"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/lead_status'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Lead Status
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            href="/admin/sales_leads"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/sales_leads'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Sales Lead
                        </Nav.Link>
                    </li>
                </ul>
            </li>
            {/* <li>
            <Nav.Link
                href="/admin/teams"
                className={`${styles.navbarLink} ${(currentPathname === '/admin/teams' || currentPathname?.includes('/admin/teams'))
                ? styles.activeLinkColor
                : ''
            }`}
            >
                Teams<i data-feather="chevron-down" style={{
                        width: '1.2rem',
                        height: '1.2rem',
                    }}></i>
            </Nav.Link>
            <ul>
                    <li>
                        <Nav.Link
                            href="/admin/team_contacts"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/team_contacts'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Team Contact
                        </Nav.Link>
                    </li>
                    </ul>
                </li> */}
                <li>
                    <Nav.Link
                            className={`${styles.navbarLink} ${currentPathname == "/admin/teams" || currentPathname?.includes('/admin/teams') 
                                    ? styles.activeLinkColor 
                                    : ''
                                } p-2`}
                        >
                            Teams <i data-feather="chevron-down" style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}></i>
                    </Nav.Link>
                    <ul>
                        <li>
                        <Nav.Link
                                href="/admin/teams"
                                className={`${styles.navbarLink} ${(currentPathname === '/admin/teams' || currentPathname?.includes('/admin/teams'))
                                ? styles.activeLinkColor
                                : ''
                            }`}
                        >
                            Team
                        </Nav.Link>
                        </li>
                        <li>
                        <Nav.Link
                                href="/admin/team_contacts"
                                className={`${styles.navbarLink} ${currentPathname === '/admin/team_contacts'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                            Team Contact
                        </Nav.Link>
                        </li>
                    </ul>
                </li>
            
                <li>
                    <Nav.Link
                            className={`${styles.navbarLink} ${currentPathname === '/cardinal/dashboards' || currentPathname === '/admin/subscription_plans' || currentPathname?.includes('/admin/subscription_plans') || currentPathname === '/admin/mixmax_dashboard' || currentPathname === '/admin/mixmax_job_analytic' || currentPathname == "/admin/incoming_mail_dashboard" || currentPathname?.includes('/admin/reference_data_management' || currentPathname === '/cardinal/dynamic_mails') 
                                    ? styles.activeLinkColor 
                                    : ''
                                } p-2`}
                        >
                            More <i data-feather="chevron-down" style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }}></i>
                    </Nav.Link>
                    <ul>
                        <li>
                        <Nav.Link
                            href="/admin/incoming_mail_dashboard"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/incoming_mail_dashboard'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Incoming Mail
                        </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/admin/reference_data_management"
                                className={`${styles.navbarLink} ${currentPathname === '/admin/reference_data_management'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Reference Data
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/admin/dynamic_mails"
                                className={`${styles.navbarLink} ${currentPathname === '/admin/dynamic_mails'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Dynamic Mails
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/admin/mixmax_job_analytic"
                                className={`${styles.navbarLink} ${
                                    currentPathname === '/admin/mixmax_job_analytic'
                                        ? styles.activeLinkColor
                                        : ''
                                }`}
                            >
                                Mixmax Job Analytics
                            </Nav.Link>
                        </li>
                        <li>
                            <Nav.Link
                                href="/admin/mixmax_dashboard"
                                className={`${styles.navbarLink} ${
                                    currentPathname === '/admin/mixmax_dashboard'
                                        ? styles.activeLinkColor
                                        : ''
                                }`}
                            >
                                Mixmax Dashboard (Account)
                            </Nav.Link>
                        </li>
                        <li>
                        <Nav.Link
                            href="/admin/subscription_plans"
                            className={`${styles.navbarLink} ${(currentPathname === '/admin/subscription_plans' || currentPathname?.includes('/admin/subscription_plans'))
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Subscription
                        </Nav.Link>
                        </li>
                        <li>
                        <Nav.Link
                            href="/admin/investor_inquiry_report"
                            className={`${styles.navbarLink} ${(currentPathname === '/admin/investor_inquiry_report' || currentPathname?.includes('/admin/investor_inquiry_report'))
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Investor Inquiry Report
                        </Nav.Link>
                        </li>
                        {isFinancer && (
                            <li>
                            <Nav.Link
                                href="/cardinal/dashboards?finance=false"
                                className={`${styles.navbarLink} ${currentPathname === '/cardinal/dashboards'
                                        ? styles.activeLinkColor
                                        : ''
                                    }`}
                            >
                                Finance
                            </Nav.Link>
                            </li>
                        )}
                    </ul>
                </li>
        </>
    )
    const financeLinks = (
        <>
            <Nav.Link
                href="/cardinal/dashboards"
                className={`${styles.navbarLink} ${currentPathname === '/cardinal/dashboards'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Finance Dashboard
            </Nav.Link>
            <Nav.Link
                href="/cardinal/commission_rates"
                className={`${styles.navbarLink} ${(currentPathname === '/cardinal/commission_rates')
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Commission Rate
            </Nav.Link>
            <Nav.Link
                href="/cardinal/employees"
                className={`${styles.navbarLink} ${currentPathname === '/cardinal/employees'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Employee
            </Nav.Link>
            <Nav.Link
                href="/cardinal/time_reports"
                className={`${styles.navbarLink} ${(currentPathname === '/cardinal/time_reports' || currentPathname?.includes('/cardinal/time_reports/detail/'))
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Time Reports
            </Nav.Link>
            <Nav.Link
                href="/cardinal/user_subscription"
                className={`${styles.navbarLink} ${(currentPathname === '/cardinal/user_subscription')
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                User Subscription
            </Nav.Link>
            <Nav.Link
                href="/admin/dashboard"
                className={`${styles.navbarLink} ${currentPathname === '/admin/dashboard'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Admin
            </Nav.Link>

        </>
    )
    const sales = (
        <>
            <Nav.Link
                href="/sales/dashboard"
                className={`${styles.navbarLink} ${
                    currentPathname === '/sales/dashboard'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            {/* <Nav.Link
                href="/agencies/recruiters"
                className={`${styles.navbarLink} ${
                    currentPathname === '/agencies/recruiters'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Recruiters
            </Nav.Link> */}
        </>
    )
    const TextMessenger = (
<>
            <Nav.Link
                href="/admin/text_messenger/dashboard"
                className={`${styles.navbarLink} ${currentPathname === '/admin/text_messenger/dashboard'
                        ? styles.activeLinkColor
                        : ''
                    }`}
            >
                Home
            </Nav.Link>
                        <Nav.Link
                            href="/admin/auto_candidate_submission"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/auto_candidate_submission'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Auto Candidate Submission
                        </Nav.Link>
                        {/* <Nav.Link
                            href="/admin/process_candidate_submission"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/process_candidate_submission'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Process Candidate Submission
                        </Nav.Link> */}
                        <Nav.Link
                            href="/admin/auto_candidate_report"
                            className={`${styles.navbarLink} ${currentPathname === '/admin/auto_candidate_report'
                                    ? styles.activeLinkColor
                                    : ''
                                }`}
                        >
                            Candidate Status for Fee Agreement
                        </Nav.Link>
        </>
    )
    return (
        <Navbar
            expand="xl"
            className={'align-items-center py-0 ' + styles.navbarContainer}
        >
            <Navbar.Brand href={host_url} className="d-none d-xl-block">
                <Image src={LogoWithText} rounded className={styles.logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="align-items-center flex-wrap">
                    <ul className={styles.navbarList}>
                        {userId && !isAdmin && authLinks}
                        {(userId && isFinancer ) && url && financeLinks}
                        {(userId && isFinancer ) && !(url) && adminLinks}
                        {userId && (isAdmin)  && adminLinks}
                        {userId && (isAgencyAdmin) && agencyAdmin}
                        {userId && (isSales) && sales}
                        {userId && (isAgency) && agency}
                        {userId && (isTextMessenger) && TextMessenger}
                    </ul>
                </Nav>
            </Navbar.Collapse>
            {userId ? (
                <>
                    {/* <Button className={styles.chatLogo} type="button">
                        <svg
                            width="30"
                            height="25"
                            viewBox="0 0 30 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 10C0 4.47715 4.47715 0 10 0H20C25.5228 0 30 4.47715 30 10V25H10C4.47715 25 0 20.5228 0 15V10Z"
                                fill="url(#paint0_linear)"
                            />
                            <circle cx="15" cy="12" r="2" fill="white" />
                            <circle cx="22" cy="12" r="2" fill="white" />
                            <circle cx="8" cy="12" r="2" fill="white" />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="0"
                                    y1="0"
                                    x2="34"
                                    y2="29"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#4C68FF" />
                                    <stop offset="1" stopColor="#8E8BFF" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </Button> */}

                    {isRecruiter && (
                        <RecruiterOrganization
                            organization={organization}
                            recruiterOrganizations={recruiterOrganizations}
                            handleChangeOrganization={handleChangeOrganization}
                        />
                    )}
                    <ProfileAvatar
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={organization ? organization.id : ''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                        userBelongsToCT={userBelongsToCT}
                        subOrgBelongsToCT={subOrgBelongsToCT}
                        isTalent={isTalent}
                    />
                </>
            ) : (
                <>
                    <a href={base_url} className={styles.navbarSignInButton}>
                        Home
                    </a>
                    <a
                        href={`/users/sign_in?page=${currentPathname == '/welcome/employer'
                                ? currentPathname
                                : currentPathname == '/welcome/recruiter'
                                    ? currentPathname
                                    : '/'
                            }`}
                        className={styles.navbarSignInButton}
                    >
                        Sign In
                    </a>
                    <Signup />
                </>
            )}
        </Navbar>
    )
}

export default NavBar
