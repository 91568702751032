import React, { useState } from 'react'
import '../RecruiterHomePage/styles/RecruiterHomePage.styled'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import styles from '../SignupPage/styles/Signup.module.scss';
import stylesAc from '../AccountSettingPage/styles/AccountSettingPage.module.scss';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Spinner } from 'react-bootstrap'
import TextInput from '../../common/TextInput/TextInput'
import {Button,StyledForm,Row} from '../CreateProfilePage/styles/CreateProfilePage.styled'
import { Email } from '@material-ui/icons';

const InvestorHome = () => {

    const [matchResult, setMatchResult] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (values, resetForm) => {
        // Add your form submission logic 
        const payload = new FormData()
        const url = '/store_investor_detail'
        console.log(values)
        payload.append('name', values.name)
        payload.append('email',  values.email)
        payload.append('investment_area',  values.invest_area)
        setLoading(true)
        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                console.log(res)
                if (res.data.success) {
                    if(res.data.result == 'Success'){
                        setMatchResult('Your inquiry has been successfully submitted. We will contact you soon.')
                        resetForm()
                    }else if(res.data.result == 'exist'){
                        setMatchResult('Your inquiry has already been registered with us. We will contact you soon.')
                    }
                        
                }
                setLoading(false)   
            },
        })
    };

    const handleSkip = async (resetForm) => {
        resetForm();
        setMatchResult('');
    };

    return (
        <>
            <div className='container'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                invest_area: ''
                            }}
                            validationSchema={
                                Yup.object({
                                    invest_area: Yup.string()
                                        .required('Investment Areas of Focus is required'),
                                        name: Yup.string()
                                        .required('Name is required'),
                                        email: Yup.string()
                                        .required('email is required')
                                })
                            }
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                handleSubmit(values, resetForm)
                            }}
                        >
                        {({ isSubmitting, resetForm }) => (
                            <StyledForm display="flex" direction="column"  style={{height:'auto',marginTop: '70px',width: 'auto', minWidth: 'auto', maxWidth: 'none', boxShadow: '4px 4px 30px rgba(0,0,0,0.1)'}}>
                                <h5 style={{color: '#3a5182',fontWeight: '700'}}>Enter your name and email address to receive referrals from top startups in your inbox!</h5>
                                <hr
                                    style={{
                                        border: 0,
                                        borderTop: "1px dashed #3a5182",
                                        height: 1,
                                        margin: "10px 0",
                                        color: '#3a5182'
                                    }}
                                    />
                                <TextInput
                                    className={styles.fullwidthInput}
                                    label="Name*"
                                    name="name"
                                    type="text"
                                    style={{ marginTop: '10px',marginBottom: '5px' }}
                                    id="name"
                                    width="100%"
                                />
                                <TextInput
                                    className={styles.fullwidthInput}
                                    label="Email*"
                                    name="email"
                                    type="email"
                                    style={{ marginTop: '10px',marginBottom: '5px' }}
                                    id="email"
                                    width="100%"
                                />
                                <TextInput
                                    className={styles.fullwidthInput}
                                    label="Investment Areas of Focus*"
                                    as="textarea"
                                    name="invest_area"
                                    type="text"
                                    style={{ marginTop: '10px',marginBottom: '5px' }}
                                    id="invest_area"
                                    width="100%"
                                    rows="2"
                                />
                                
                                <div className='row'>
                                    <Button type="submit" disabled={loading} className={` mr-3`} style={{background: '#005999',color: '#fff'}}>
                                        {loading ? (
                                            <Spinner
                                                animation="border"
                                                variant="light"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                    <Button type='button' onClick={() => handleSkip(resetForm)}  style={{background: '#fff',color: '#005999', borderColor: '#005999', border: '1px solid'}}>
                                        Reset
                                    </Button>
                                    
                                    {matchResult != '' && (
                                        <>  
                                            <br/>
                                            <h6 className='mt-5' style={{color: '#5c8b5c',fontWeight: '700'}}>{matchResult}</h6>
                                        </>
                                    )}
                                </div>
                            </StyledForm>
                        )}
                        </Formik>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default InvestorHome
