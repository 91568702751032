import React, { useEffect, useState } from 'react'
import P from './shared/P'
import { Row,Col,Spinner } from 'react-bootstrap'
import Select from 'react-select'
import styles from './styles/JobsStatusPage.module.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import { CSVLink } from "react-csv";
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import Util from "../../../utils/util";
import Card from './shared/Card'
import SearchBar from '../../common/SearchBar/SearchBar'
import DataTable from "react-data-table-component-footer"
import   './styles/DataTableCustom.scss'

const InvestorInquiryReport = ({per_page=25}) => {
  var active_page = window.sessionStorage.getItem('activePage')
  var query = window.sessionStorage.getItem('query')
  const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
  const [searchTerm, setSearchTerm] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(per_page)
  const [tableDatas, setTableDatas] = useState([])
  const [totalDatas,setTotalDatas] = useState(0)
  const [currentCounts,setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [dateRange, setDateRange] = useState({startDate: moment().startOf('week').toDate() ,endDate: moment().toDate()})
  const [loading, setLoading] = useState(true)
  const [sortField, setSortField] = useState('created_at')
  const [sortDirection, setSortDirection] = useState('desc')
  const tableHeaders = [
    { label: 'Investor Name', key: 'name' },
    { label: 'Investor Email', key: 'email' },
    { label: 'Investment Area', key: 'investment_area' },
    { label: 'Inquiry On', key: 'created_at' },
  ]
  const fetchData = () => {
    setLoading(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/investor_inquiry_report.json`
    window.sessionStorage.setItem('activePage',activePage)
    makeRequest(`${url}?start_date=${start_date}&search=${searchTerm}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`, 'get',  {params: {page: (activePage), query: query}}, {   
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        console.log(res.data)
        setTotalDatas(res.data.total_count)
        let sr_no = (activePage - 1) * res.data.per_page
        setTableDatas(
          res.data.list.map((inMails) => (sr_no = sr_no + 1,{
            id: sr_no,
            name: inMails.name,
            email:inMails.email,
            investment_area: inMails?.investment_area,
            created_at: inMails.created_at ? moment(inMails?.created_at).format('MMM DD, YYYY') : " ",
          }))
        )
        setPageCount(res.data.total_pages)
        setCurrentCounts(res.data.current_counts)
        setStartRecord((activePage - 1) * res.data.per_page)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    fetchData()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activePage,dateRange,sortDirection,sortField])

  const handleDate = (date) => {
    setDateRange(date)
  }

  const onChangeSearchTerm = (value) => {
    setSearchTerm(value)
 }

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = `/admin/investor_inquiry_report.json`
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.list.map((inMails,key)=> ({
        name: inMails.name,
        email:inMails.email,
        investment_area: inMails?.investment_area,
        created_at: inMails.created_at ? moment(inMails?.created_at).format('MMM DD, YYYY') : " ",
    })))
    csvExportRef?.current?.link.click();
  }

  const columns = [
    {
      name: 'No.',
      selector: row => row.id,
      maxWidth: '10%',
      minWidth: '10%'
    },
    {
      name: 'Investor Name',
      selector: row => row.name,
      cell: (row) => <div title={row.name}>{row.name}</div>,
      maxWidth: '20%',
      minWidth: '20%',
      wrap:true,
      sortable: true,
      sortField: 'name'
    },
    {
      name: 'Investor Email',
      selector: row => row.email,
      cell: (row) => <div title={row.email}>{row.email}</div>,
      minWidth: '20%',
      maxWidth: '20%',
      wrap:true,
      sortable: true,
      sortField: 'email'
    },
    {
      name: 'Investment Area',
      selector: row => row.investment_area,
      cell: (row) => <div title={row.investment_area}>{row.investment_area}</div>,
      minWidth: '30%',
      maxWidth: '30%',
      wrap:true,
      sortable: true,
      sortField: 'investment_area'
    },
    {
      name: 'Inquiry On',
      selector: row => row.created_at,
      cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
      maxWidth: '20%',
      minWidth: '20%',
      wrap:true,
      sortable: true,
      sortField: 'created_at'
    }
  ]

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
            Investor Inquiry Report
        </P>
        <div>
        <button className={`ml-3 ${styles.exportCSVButton}`} onClick={exportDataMethod}>
              Export CSV
        </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={tableHeaders}
        filename={"InvestorInquiry.csv"}
        ref={csvExportRef}
      >
      </CSVLink>
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-end'>
            {totalDatas > 0 && loading == false &&
                <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalDatas}</DisplayPagination>
             }
        </Col>
        <Col lg="10" md="10" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Select Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
          <div style={{display: 'grid',marginTop:'34px'}}>
            <SearchBar
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
                onEnterPressed={() =>
                    activePage == 1
                        ? fetchData()
                        : setActivePage(1)
                }
                onButtonClick={() =>
                    activePage == 1
                        ? fetchData()
                        : setActivePage(1)
                }
            />
            </div>
        </Col>
      </Row>
      <DataTable
          persistTableHead={true}
          noDataComponent={<p className="text-center p-3">No record found</p>}
          columns={columns}
          data={tableDatas}
          progressPending={loading}
          progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
              <Spinner className='spinner' animation="border" role="status">
                  <span className="sr-only">Loading...</span>
              </Spinner>
          </P>}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalDatas}
          paginationPerPage={per_page}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          responsive={true}
          onChangePage={page => setActivePage(page)}
      />
    </Card>
    </>
  )
}

export default InvestorInquiryReport