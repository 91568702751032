import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import styles from './styles/CandidateInfo.module.scss'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, Modal } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from "react-bootstrap/Badge";
import './styles/CandidateInfo.scss'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import _ from 'lodash'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import moment from 'moment'
import feather from 'feather-icons'
import EmployerMail_v2 from '../../common/EmailClient/EmployerMail_v2'

const MAX_SELECTION_LIMIT = 25
const CANDIDATES_PER_PAGE = 25
function CandidateInfo_v2({
  loading,
  candidates,
  handleIntrested,
  multiSelectCheckbox,
  multiIntrestedCandidates,
  current_user,
  setSelectCandidate,
  checkedItems,
  setCheckedItems,
  page,
  check,
  is_email_config,
  showSubcriptionAlert,
  setShowSubcriptionAlert,
  setCheckIsEmailConfigured,
  check_plan,
  checkIsEmailConfigured,
  ct_member
}) {

  const [submissionData, setSubmissionData] = useState({ active: false, id: null })
  const [expanded, setExpanded] = useState({company: false, position: false, location: false, industry: false})
  function activeSubmission(candidateId) {
    setSubmissionData({ active: true, id: candidateId })
  }
  useEffect(() => {
    feather.replace();
  }, []);

  if (loading) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px',fontSize: '20px' }}>
        <h2 style={{fontSize: '16px'}}>Loading....</h2>
        <div id="loading" />
      </div>
    </>
    )
  }
  if (isEmpty(candidates)) {
    return (<>
      <div className='container' style={{ textAlign: 'center', margin: '119px -2px' }}>
        <h2 style={{fontSize: '16px'}}>No Record Found</h2>
      </div>
    </>
    )
  }

  const candidateClickHandler = (candidate) => {
  }
  return (
    <>
      <div className="employers-list">
          {Array.isArray(candidates) &&
            candidates.map((candidate, index) => {
              return (
                <CandidateRow
                  candidate={candidate}
                  idx={candidate}
                  key={index}
                  clickHandler={candidateClickHandler}
                  setSubmissionData={setSubmissionData}
                  submissionData={submissionData}
                  activeSubmission={activeSubmission}
                  handleIntrested={handleIntrested}
                  multiSelectCheckbox={multiSelectCheckbox}
                  multiIntrestedCandidates={multiIntrestedCandidates}
                  current_user={current_user}
                  setSelectCandidate={setSelectCandidate}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  check={check}
                  is_email_config={is_email_config}
                  showSubcriptionAlert={showSubcriptionAlert}
                  setShowSubcriptionAlert={setShowSubcriptionAlert}
                  check_plan={check_plan}
                  setCheckIsEmailConfigured={setCheckIsEmailConfigured}
                  checkIsEmailConfigured={checkIsEmailConfigured}
                  ct_member={ct_member}
                />
              )
            })
          }
      </div>
    </>
  )
}

function CandidateRow({
  candidate,
  idx,
  clickHandler,
  current_user,
  checkedItems,
  setCheckedItems,
  check,
  multiIntrestedCandidates,
  multiSelectCheckbox,
  is_email_config,
  showSubcriptionAlert,
  setCheckIsEmailConfigured,
  setShowSubcriptionAlert,
  check_plan,
  checkIsEmailConfigured
  }) {
  const [validationError, setValidationError] = useState({})
  const checked = multiIntrestedCandidates.length > 0 ? multiIntrestedCandidates.includes(idx) : false
  const [bulkSelect, setBulkSelect] = useState(checked)
	const [btnDisabled, setBtnDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showSentEmailModal, setShowSentEmailModal] = useState(false)
  const [emailData, setEmailData] = useState()
  const [selectuser, setSelectUser] = useState([])
  const [childCheckbox, setChildCheckbox] = useState(false);
  useEffect(() => {
    setChildCheckbox(check);
  },[check])
  let candidates =  candidate?.user_id?.split(",").map(Number);
  useEffect(() => {
    feather.replace()
  })
  const transformedArray = candidates?.map((element) => {
    // Code to transform each element goes here
    // For example, you can perform some operation on each element
    return element ;
  });
  
  function getSelectCell(candidate, col) {
    return (
      <>
      {!candidates?.includes(current_user?.id) && (candidate.email != null && candidate.email != '') &&
        <Form.Check
          className={`${styles.candidateCheckbox} check_${candidate.id}`}
          type="checkbox"
          value={candidate.firstName}
          name={candidate.lastName}
          checked={checked}
          onChange={handleCheckboxChange}
          onClick={(event) => event.stopPropagation()}
        />
      }
     </>
    )
  }

  const handleCheckboxChange = (event) => {
    setBulkSelect(!checked)
    multiSelectCheckbox(candidate)
  }
  return (
    <>
        <div className="card-employers hover-up wow animate__ animate__fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
            <div className="row align-items-center">
                <div className="col-lg-9 col-md-9 col-sm-9 d-flex pl-0">
                    {getSelectCell(candidate)}
                    <div className="employers-logo online mr-15">
                        {candidate?.image_url ? <img alt={candidate?.first_name} onClick={(e) => clickHandler(candidate)} src={candidate.image_url ? candidate.image_url : profileImage} /> : 
                           <CandidateAvatarIcon clickHandler={clickHandler} candidate={candidate} firstName={candidate?.first_name} lastName={candidate?.last_name} /> }
                    </div>
                    <div className="employers-name">
                        <div className='d-flex'><h5><strong>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</strong></h5>
                        { (candidate?.linkedin_profile_url && candidate?.linkedin_profile_url != '' && candidate?.linkedin_profile_url != null && candidate?.linkedin_profile_url != 'null') && 
                          <>
                              <a href={candidate?.linkedin_profile_url} target='_blank'><img src={LinkedinIcon} style={{marginLeft: '30px'}}  width={'20px'} height={'20px'} /></a>
                          </>
                        }
                        </div>
                        { (candidate?.position != null && candidate?.position != '')  &&
                           <><span className="text-sm text-muted" style={{fontWeight: '800'}}>{candidate?.position}</span><br /></>
                        }
                        { (candidate?.email && candidate?.email != '' && candidate?.email != null && candidate?.email != 'null') && 
                            <> <span className="text-sm text-muted">{ '********@'+candidate.email.split('@')[1] }</span><br /> </> 
                        }
                         { (candidate?.location && candidate?.location != '' && candidate?.location != null && candidate?.location != 'null') && 
                            <> <span className="text-sm text-muted">{candidate?.location}</span><br /> </> 
                        }

                        <span className="text-sm text-muted">{`${candidate.company_name ? candidate?.company_name : ''}`}</span>
                        
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 text-lg-end d-lg-block">
                   {(candidate.email != null && candidate.email != '') && 
                    <div className="mt-25">
                    {candidates?.includes(current_user?.id) ? 
                        <a className="btn btn-border"style={{background: '#45a049', color: '#fff'}} onClick={(() =>
                          {
                           setShowSentEmailModal(true)
                           setEmailData(candidate)
                          }
                           )}>Email Sent</a>
                      : <a className="btn btn-border btn-brand-hover"style={{background: '#005999', color: '#fff'}} onClick={() =>{
                            if (is_email_config == null){
                            setCheckIsEmailConfigured(true)
                            }
                            if ((check_plan?.max_limit > check_plan?.used_limit) && is_email_config != null){
                            setShowModal(true)
                            }
                            else{
                            setShowSubcriptionAlert(true)
                            }
                            }
                          }>Send Email</a> 
                    }
                    </div>
                   }
                </div>
            </div>
        </div>    
        <Modal
          style={{padding:'20px'}}
            // className="free-candidate"
            show={showSentEmailModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            
        >
            <Modal.Header
            onHide={() => setShowSentEmailModal(false)}
            closeButton
            >
                <Modal.Title>{'Last Email'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <EmployerMail_v2
                          emailData={emailData}
                          seeEmail={true}
                          emailClientId={'emailclientfor_' + 'scheduler'}
                          userId={current_user?.id}
                          isEmailConfigured={true}
                          userEmail={current_user?.email || ''}
                          showByDefault={true}
                          mailSentCallback={() => {}}
                          sendList={[candidate]}
                          embedded={true}
                          setShowModal={setShowModal}
                          setShowSentEmailModal={setShowSentEmailModal}
                      />
                    </Modal.Body>
        </Modal>
          <Modal
            style={{padding:'20px'}}
              // className="free-candidate"
              show={showModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              scrollable
              
          >
              <Modal.Header
              onHide={() => setShowModal(false)}
              closeButton
              >
                  <Modal.Title>{'Send mail'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <EmployerMail_v2
                    emailClientId={'emailclientfor_' + 'scheduler'}
                    userId={current_user?.id}
                    isEmailConfigured={true}
                    userEmail={current_user?.email || ''}
                    showByDefault={true}
                    mailSentCallback={() => {}}
                    sendList={[candidate]}
                    embedded={true}
                    setShowModal={setShowModal}
                />
              </Modal.Body>
          </Modal>
      
    </>
  )
}


export default CandidateInfo_v2