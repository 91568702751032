import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import NavLink from 'react-bootstrap/NavLink'
import styles from './styles/Footer.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'

const Footer = ({version,base_url,host_url}) => {
    return (
        <Row
            className={`justify-content-between align-items-center py-3 px-3 mx-0 ${styles.footerContainer}`}
        >
            <Navbar  className="d-flex flex-column py-0 w-100">
                <Navbar.Toggle />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className={`w-100 flex-column`}
                >
                    <div className={styles.footerLinks} >
                        <Navbar.Brand href={host_url} className="d-none d-lg-block">
                            <Image
                                src={LogoWithText}
                                rounded
                                className={styles.footerLogo}
                            />
                        </Navbar.Brand>
                        <div className="d-flex flex-column ml-5">
                            <p className={'mb-2 '}>
                                CardinalTalent
                            </p>
                            <a
                                href="/welcome/about_us"
                                className={`${styles.footerLink} ${styles.footerLinkPadding}`}
                            >
                                About Us
                            </a>
                            
                        </div>
                    </div>
                    <div className={`d-flex ${styles.privacyP}`}>
                        <NavLink
                            href="/privacy_policy"
                            className={styles.footerLink}
                        >
                            Privacy Policy
                        </NavLink>
                        <span className={styles.footerpip}>|</span>
                        <NavLink
                            href="/terms_of_service"
                            className={styles.footerLink}
                        >
                            Terms of Service
                        </NavLink>
                        <span className={styles.footerpip}>|</span>
                        <NavLink
                            href="/ccpa"
                            className={styles.footerLink}
                        >
                            Do Not Sell My Personal Info
                        </NavLink>
                        <span className={styles.footerpip}>|</span>
                        <NavLink
                            href="/optout"
                            className={styles.footerLink}
                        >
                            Opt Out
                        </NavLink>
                        <span className={styles.footerpip}>|</span>
                        <NavLink
                            href="https://www.notion.so/cardinal-talent/Release-Notes-16e1b2e484a147148a126f1f22efdce8"
                            className={styles.footerLink}
                        >
                            {version}
                        </NavLink>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </Row>
    )
}

export default Footer
